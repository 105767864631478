.about-us-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    color: #333;
    line-height: 1.6;
  }
  
  .about-us-header {
    text-align: center;
    padding: 20px;
    background-color: #02457A;
    color: #fff;
    border-radius: 8px;
    margin-bottom: 20px;
  }
  
  .about-us-header h1 {
    font-size: 2.5rem;
  }
  
  .about-us-header .brand-name {
    color: #FFD700; /* Accent color for branding */
  }
  
  .about-us-mission, 
  .about-us-services, 
  .about-us-values, 
  .about-us-why-choose-us, 
  .about-us-contact {
    margin-bottom: 40px;
  }
  
  .about-us-services .service-cards {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
  }
  
  .service-card {
    flex: 1;
    min-width: 300px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 15px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .service-card h3 {
    font-size: 1.2rem;
    color: #02457A;
  }
  
  .about-us-values ul, 
  .about-us-why-choose-us ul {
    list-style-type: disc;
    margin-left: 20px;
  }
  
  .contact-cta {
    text-align: center;
    padding: 20px;
    background-color: #f1f1f1;
    border-radius: 8px;
  }
  
  .contact-button {
    display: inline-block;
    margin-top: 10px;
    padding: 10px 20px;
    background-color: #02457A;
    color: #fff;
    border-radius: 5px;
    text-decoration: none;
    font-size: 1rem;
  }
  
  .contact-button:hover {
    background-color: #022C57;
  }
  