@tailwind base;
@tailwind components;
@tailwind utilities;
/* slick-carousel styles */
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";


@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

body {
  margin: 0;
  font-family: 'Lato', sans-serif;
}

.hero {
  background: url(./images/hero-bg.svg) top center no-repeat;
  background-size: cover;
}

.footer {
  background: url(./images/footer.svg) top center no-repeat;
  background-size: cover;
}

.cta {
  background: linear-gradient(rgba(2,2,2,.7),rgba(0,0,0,.7)),url(https://images.unsplash.com/photo-1497215728101-856f4ea42174?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80) fixed center center;
}


.carousel-container {
  width: 100%;
  max-width: 800px;
  margin: auto;
  overflow: hidden;
}

.carousel-image {
  width: 100%;
  height: auto;
  object-fit: contain;
}



.slick-prev:before,
.slick-next:before {
  color: #02457A; /* Arrow color */
  font-size: 20px;
}


.bg-gray-100 {
  background-color: #f7f7f7;
}

.shadow-lg {
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.hover\:shadow-lg:hover {
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15);
}


